import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide28/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide28/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide28/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide28/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide28/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Ciclo de cuentas por cobrar: Qué es y cómo funciona
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El proceso de cobranza desempeña un papel crítico en la administración
      financiera de una empresa y se convierte en una campaña estratégica para
      asegurar el flujo de efectivo necesario.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Esta campaña de cobranza se adentra en el ciclo de cuentas por cobrar, un
    proceso que se inicia desde el momento en que se concreta una venta y se
    extiende hasta la recepción del pago correspondiente.
    <br /> <br />A medida que las empresas ofrecen bienes y servicios a crédito,
    es fundamental entender cómo funciona este ciclo para llevar a cabo una
    campaña de cobranza eficiente y equilibrada.
  </p>
)

const data = {
  start: {
    support: "Guía | Cobranzas",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Establecimiento de los términos de crédito.",
      },
      {
        key: 2,
        text: "Emisión de facturas.",
      },
      {
        key: 3,
        text: "Registro y seguimiento de cuentas por cobrar.",
      },
      {
        key: 4,
        text: "Recordatorio y seguimiento de pagos.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
